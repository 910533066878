.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.at_page {
    padding: 30px;
    background-color: #F5F5F5;
    min-height: 70vh;
}

.at_artist_list .col-md-2 {
    margin-bottom: 15px;
}

.at_artist_list .at_artist_box {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #181818;
    padding: 30px 15px;
    height: 100%;
}

.at_artist_list .at_box_img span {
    width: 40px;
    height: 40px;
    background-color: #121212;
    color: #fff;
    display: block;
    border-radius: 50%;
    text-align: center;
    font-size: 15px;
    line-height: 40px;
}

.at_artist_list .at_box_name {
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}

.at_artist_list {
    padding-top: 20px;
}

.container-fluid.at_chart {
    padding: 0 50px;
}

.at_chart .at-chat-box {
    background: #fff;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    padding: 20px;
}

.at-chat-box h2 {
    font-size: 24px;
}

.at-artist-img {
    background-color: #121212;
    font-size: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.at-tour-list {
    padding-bottom: 30px;
}

.at-tour-list a {
    display: block;
    background-color: #fff;
    border: 1px solid #E6EAEB;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;
    color: #181818;
    margin: 10px 0;
}

.at-tour-list a:hover {
    background-color: #C4EDFF;
    transition-duration: 0.6s;
}

.at-tour-list a .row {
    align-items: center;
}

.at-tour-list a h3 {
    margin: 0;
    font-size: 24px;
}

.at-tour-list a h3 small {
    font-size: 16px;
    display: block;
    color: #565656;
    font-weight: bold;
}

.at-tour-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.at-tour-list ul li {
    list-style: none;
}

.container-fluid.sec-pedding {
    padding: 0px 30px;
}

.at-contect h1 {
    font-size: 42px;
    font-weight: 200;
}

.at-contect h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0;
}

.at-contect h2 small {
    display: block;
    font-size: 16px;
}

.at-contect h3 {
    margin: 20px 0;
}

.at-contect h3 small {
    display: block;
    font-size: 16px;
    color: #565656;
    font-weight: bold;
}

.at-pagi {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
}

.at-pagi button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 26px;
    font-size: 15px;
    text-align: center;
    border: 1px solid #121212;
    padding: 0;
    margin: 10px;
}

.at-pagi button.active {
    background-color: #121212;
    color: #fff;
}

.at-pagi button[disabled] {
    opacity: 0.5;
}

.ev-bd-text svg {
    max-height: 100px;
    width: 80%;
    margin: auto;
    text-align: center;
}

.ev-bd-card .card {
    height: 100%;
}

.ev-bd-card .col-md-4 {
    margin-bottom: 20px;
}